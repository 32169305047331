export const translationData = {
  "en": {
    "title": `Push Notifications`,
    "allowText": "Please click the \"Allow\" button to receive notifications about new bonuses, promotions, and tournaments."
  },
  "ca": {
    "title": `Push Notifications`,
    "allowText": "Please click the \"Allow\" button to receive notifications about new bonuses, promotions, and tournaments."
  },
  "ru": {
    "title": `Подписка на уведомления`,
    "allowText": "Нажмите «Разрешить» для получения уведомлений о бонусах, акциях и турнирах."
  },
  "ua": {
    "title": `Підписка на пуш-повідомлення`,
    "allowText": "Натисніть «Дозволити» для отримання повідомлень про бонуси, акції та турніри."
  },
  "de": {
    "title": `Push-Benachrichtigungen`,
    "allowText": "Klicke auf \"Erlauben\", um Benachrichtigungen über Boni, Aktionen und Turniere zu erhalten."
  },
  "fi": {
    "title": `Työntöilmoitukset`,
    "allowText": "Napauta \"Salli\" -painiketta saadaksesi ilmoituksia uusista bonuksista, kampanjoista ja turnauksista."
  },
  "fr": {
    "title": `Notifications push`,
    "allowText": "Veuillez appuyer sur le bouton « Autoriser » pour recevoir des notifications sur les nouveaux bonus, promotions et tournois."
  },
  "kz": {
    "title": `Пуш-хабарландырулар`,
    "allowText": "Бонустар, науқандар және турнирлер туралы хабарландырулар алу үшін «Рұқсат беру» батырмасын басыңыз."
  },
  "pt": {
    "title": `Notificações push`,
    "allowText": "Toque no botão \"Permitir\" para receber notificações sobre novos bônus, promoções e torneios."
  },
  "es": {
    "title": `Notificaciones automáticas`,
    "allowText": "Pulsa el botón \"Permitir\" para recibir notificaciones sobre nuevos bonos, promociones y torneos."
  },
  "da": {
    "title": `Push Notifications`,
    "allowText": "Please click the \"Allow\" button to receive notifications about new bonuses, promotions, and tournaments."
  },
  "no": {
    "title": `Push Notifications`,
    "allowText": "Please click the \"Allow\" button to receive notifications about new bonuses, promotions, and tournaments."
  },
  "pl": {
    "title": `Push Notifications`,
    "allowText": "Please click the \"Allow\" button to receive notifications about new bonuses, promotions, and tournaments."
  },
  "ja": {
    "title": `プッシュ通知`,
    "allowText": "新しいボーナス、最新プロモーションやトーナメントに関する通知をご希望の方は、「受け取る」ボタンをクリックしてください。"
  },
  "tr": {
    "title": `Anlık Bildirimler`,
    "allowText": "Yeni bonuslar, promosyonlar ve turnuvalar hakkında bildirim almak için lütfen \"İzin Ver\" düğmesine tıklayın."
  },
}

