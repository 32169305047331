import { translationData } from "./data";

export type KeyList = "title" | "allowText";
export type LangList =
  | "en"
  | "da"
  | "es"
  | "kz"
  | "ca"
  | "de"
  | "no"
  | "pl"
  | "pt"
  | "ru"
  | "ua"
  | "fi"
  | "fr";

export type TranslationData = Record<LangList, Record<KeyList, string>>;
export const getTranslate = (projectTranslations?: TranslationData) => {
  const getLangCode = (): LangList => {
    const queryParameters = new URLSearchParams(window.location.search);
    const lang = queryParameters.get("lang") as LangList;
    return lang || "en";
  };

  const getTranslationData = (
    langCode: LangList,
    translationData: TranslationData,
  ) => {
    return translationData[langCode];
  };

  const lang = getLangCode();

  const translationSource = projectTranslations ?? translationData;

  return getTranslationData(lang, translationSource);
};
